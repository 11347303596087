@keyframes spinner-line-fade-more {
  0%, 100% {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-quick {
  0%, 39%, 100% {
    opacity: .25;
  }

  40% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-default {
  0%, 100% {
    opacity: .22;
  }

  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-shrink {
  0%, 25%, 100% {
    opacity: .25;
    transform: scale(.5);
  }

  26% {
    opacity: 1;
    transform: scale(1);
  }
}

/*# sourceMappingURL=merge.0e5bdf71.css.map */
